import { render, staticRenderFns } from "./renwuxinxi.vue?vue&type=template&id=e11fdc66&scoped=true&"
import script from "./renwuxinxi.vue?vue&type=script&lang=ts&"
export * from "./renwuxinxi.vue?vue&type=script&lang=ts&"
import style0 from "./renwuxinxi.vue?vue&type=style&index=0&id=e11fdc66&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e11fdc66",
  null
  
)

export default component.exports