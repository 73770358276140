



















































































































































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { PostEditRenwu, RenwuDetail } from "@/request/mark";
import { GetZhuantis } from "@/request/schema";
import { GetBookCategory } from "@/request/storehouse";
@Component({})
export default class Name extends Vue {
  @Prop()
  private data: any;
  @Watch("data", { immediate: true })
  private dataChange() {
    this.detail = JSON.parse(JSON.stringify(this.data));
  }
  @Prop()
  private ShowEdit: any;
  @Prop({ type: Boolean, default: false })
  private ifShowFenpeiStatu: any;
  private ifEdit: any = false;
  private detail: any = {};
  private zhutis: any = [];
  private fenleis: any = [];
  private edit() {
    this.ifEdit = true;
  }
  private cancel() {
    this.detail = JSON.parse(JSON.stringify(this.data));
    this.ifEdit = false;
  }
  private save() {
    const str: any = [
      "编码",
      "章节",
      "原文",
      "主题分类",
      "文体分类",
      "释义",
      "关联论述编码",
      "关联论述原文",
      "图谱",
      "医家",
      "年代",
      "源文献",
    ];
    const params: any = {
      task_id: this.detail.id,
    };
    str.forEach((ele: any) => {
      params[ele] = this.detail[ele];
    });
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    PostEditRenwu(this, params, loading).then((res: any) => {
      const params2: any = {
        task_id: this.detail.id,
        kind: "任务标注",
      };
      RenwuDetail(this, params2).then((res: any) => {
        loading.close();
        this.$emit("change", res);
        this.ifEdit = false;
      });
    });
  }
  private getZhutis() {
    GetZhuantis(this).then((res: any) => {
      this.zhutis = res;
    });
  }
  private getFenleis() {
    GetBookCategory(this)
      .then((res: any) => {
        this.fenleis = res;
      })
      .catch(() => {});
  }
  private mounted() {
    this.getZhutis();
    this.getFenleis();
  }
}
